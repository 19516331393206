import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../layout"
import SEO from "../seo"
import { format } from "date-fns"

export default ({ data, location }) => {
  const { mdx } = data // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx
  return (
    <div style={{backgroundColor: "#f8f9fa"}}>
      <Layout>
        <SEO title={frontmatter.title}/>
        <div className="row container mx-auto " style={{maxWidth: "700px"}}>
          <div className="col-12 px-0 mb-3 d-flex back-btn-container" style={{left: "-20px"}}>
            {"< "}
            <Link to="/articles" className="back-btn"> 
              <h5 className="m-0 pl-2">
                {`Back to articles`}
              </h5>
            </Link>
          </div>
          <div className="col-12 px-0 mt-1">
            <h1 className="mt-3 mb-0 article-title">
              {frontmatter.title}
            </h1>
            <h2 className="article-summary">
              {frontmatter.shortDesc}
            </h2>
            <h6 className="mt-0 mb-4"><b>
              {format(new Date(frontmatter.date), "iii, dd MMM yyyy")}</b>
            </h6>
            <div className={`lato article`}>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        title
        shortDesc
        date
        coverImg {
          childImageSharp {
            fluid(maxWidth: 1000) {
              # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
